<template>
  <div class="layer-page-div">
    <div class="item-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <label class="item-name-label">登录账号：</label>
          <validation-provider rules="required|account" v-slot="{ errors }" name="account">
            <label>
              <input type="text" v-model="acc" placeholder="请输入账号" />
            </label>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <label class="item-name-label">用户姓名：</label>
          <validation-provider rules="required|userName" v-slot="{ errors }" name="userName">
            <label>
              <input type="text" v-model="name" placeholder="请输入姓名" />
            </label>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <label class="item-name-label">登录密码：</label>
          <validation-provider rules="required|minmax:6,18" v-slot="{ errors }" name="password">
            <label>
              <input type="password" v-model="pwd1" placeholder="请输入登录密码" />
            </label>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <label class="item-name-label">确认密码：</label>
          <validation-provider rules="required|confirmed:password" v-slot="{ errors }" name="confirmPwd">
            <label>
              <input type="password" v-model="pwd2" placeholder="请确认密码" />
            </label>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <label class="item-name-label">联系电话：</label>
          <validation-provider rules="phone" v-slot="{ errors }" name="phone">
            <label>
              <input type="text" v-model="phone" placeholder="请输入手机号码" />
            </label>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="operation-div">
          <label class="item-name-label">管理类型：</label>
          <div class="item-msg-div">
            <label><input type="radio" v-model="mangerType" checked value="1"> 平台管理员</label>
            <label class="manager-type"><input type="radio" v-model="mangerType" value="2"> 商会管理员</label>
          </div>
        </div>
        <div v-show="mangerType === '1'" class="item-div">
          <div class="role-group-div">
            <label class="item-name-label">管理角色：</label>
            <my-select class="mySelect" :load-more="true" :action="action" :change="onChanged" placeholder-txt="请选择角色"/>
          </div>
          <div class="tips-div"></div>
        </div>
        <div v-show="mangerType === '2'" class="item-div">
          <div class="role-group-div">
            <label class="item-name-label">管理商会：</label>
            <my-select class="mySelect" :load-more="true" :action="firmNameAction" :change="onChanged" placeholder-txt="请选择商会"/>
          </div>
          <div class="tips-div"></div>
        </div>
      </validation-observer>
    </div>
    <div class="submit-div">
      <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
      <input type="button" value="确  定" @click="onSubmit" />
    </div>
  </div>
</template>

<script>
import MySelect from "../../components/MySelect"
import { getGroupNameItems, getFirmNameItems, postManagerAdd } from "../../common/api";

export default {
  components: { MySelect },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      action: getGroupNameItems,
      firmNameAction: getFirmNameItems,
      acc: "",
      pwd1: "",
      pwd2: "",
      name: "",
      phone: "",
      mangerType: "1",
      objId: "",
      tipMsg: ""
    };
  },
  methods: {
    onChanged(item) {
      this.objId = item.id;
    },
    onSubmit() {
      this.$refs.form.validate().then(res => {
        if (res) {
          if(this.objId.length < 1){
            this.setTipsMsg(this.mangerType === "1" ? "请选择角色" : "请选择商会");
            return;
          }

          let params = {
            acc: this.acc,
            pwd: this.$sraEncode(this.pwd1),
            name: this.name,
            phone: this.phone,
            type: this.mangerType,
            objId: this.objId
          };

          postManagerAdd(params)
            .then(() => {
              this.refreshData();
              this.$layer.close(this.layerid);
              this.$layer.msg("添加管理员成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("添加管理员失败");
              }
            });
        }
      });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
};
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .item-content-div {
    width: 360px;
    margin: 20px auto 0 auto;
    .item-div {
      .item-name-label {
        margin-right: 10px;
      }
      input {
        width: 220px;
        height: 33px;
        padding: 0 5px;
        border: 1px solid #dcdcdc;
      }
      .role-group-div {
        display: inline-flex;
        align-items: center;
        .mySelect {
          width: 232px;
          height: 33px;
        }
      }
      .tips-div {
        width: 232px;
        height: 30px;
        margin-left: 80px;
      }
    }
    .operation-div {
      height: 45px;
      display: flex;
      .item-name-label {
        margin-right: 10px;
      }
      .item-msg-div {
        display: flex;
        input[type="radio"] {
          width: 18px;
          height: 18px;
          vertical-align: middle;
        }
        .manager-type {
          margin-left: 15px;
        }
      }
    }
  }
  .submit-div {
    text-align: center;
    margin-top: 15px;
    input {
      width: 120px;
      height: 35px;
      color: white;
      font-size: 16px;
      border: none;
      background: #4676c8;
      margin-top: 10px;
    }
    .tips-div {
      width: 100%;
      height: 25px;
    }
  }
}
</style>
