<template>
  <div class="layer-page-div">
    <div class="item-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <label class="item-name-label">登录账号：</label>
          <validation-provider rules="required|account" v-slot="{ errors }" name="account">
            <label>
              <input type="text" v-model="managerInfo.acc" placeholder="登录账号" readonly />
            </label>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <label class="item-name-label">用户姓名：</label>
          <validation-provider rules="required|userName" v-slot="{ errors }" name="userName">
            <label>
              <input type="text" v-model="managerInfo.name" placeholder="请输入姓名" />
            </label>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <label class="item-name-label">联系电话：</label>
          <validation-provider rules="phone" v-slot="{ errors }" name="phone">
            <label>
              <input type="text" v-model="managerInfo.phone" maxlength="11" placeholder="请输入手机号码" />
            </label>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div v-if="managerInfo.type === 1" class="item-div">
          <div class="role-group-div">
            <label class="item-name-label">管理角色：</label>
            <my-select class="my-select" :load-more="true" :action="action" :change="onChanged" placeholder-txt="请选择角色"/>
            <label>（{{ managerInfo.group }}）</label>
          </div>
          <div class="tips-div"></div>
        </div>
        <div v-else class="item-div">
          <div class="role-group-div">
            <label class="item-name-label">管理商会：</label>
            <my-select class="my-select" :load-more="true" :action="firmNameAction" :change="onChanged" placeholder-txt="请选择商会"/>
          </div>
          <div class="firm-name-div">（{{ managerInfo.group }}）</div>
        </div>
      </validation-observer>
    </div>
    <div class="submit-div">
      <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
      <input type="button" value="确  定" @click="onSubmit" />
    </div>
  </div>
</template>

<script>
import MySelect from "../../components/MySelect"
import { postEditManagerInfo, getGroupNameItems, postEditFirmManagerInfo, getFirmNameItems } from "../../common/api";

export default {
  components: { MySelect },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    managerInfo: {
      type: Object,
      default: {}
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      action: getGroupNameItems,
      firmNameAction: getFirmNameItems,
      objId: "",
      tipMsg: ""
    };
  },
  methods: {
    onChanged(item) {
      this.objId = item.id;
    },
    doEditManager(params) {
      postEditManagerInfo(params)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("编辑管理员成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("编辑管理员失败");
          }
        });
    },
    doEditFirmManager(params) {
      postEditFirmManagerInfo(params)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("编辑管理员成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("编辑管理员失败");
          }
        });
    },
    onSubmit() {
      if(this.objId.length < 1){
        this.setTipsMsg(this.managerInfo.type === 1 ? "请选择角色" : "请选择商会");
        return;
      }

      this.$refs.form.validate().then(res => {
        if (res) {
          let params = {
            id: this.managerInfo.id,
            name: this.managerInfo.name,
            phone: this.managerInfo.phone,
            objId: this.objId
          };

          if(this.managerInfo.type === 1){
            this.doEditManager(params);
          }else{
            this.doEditFirmManager(params);
          }
        }
      });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
};
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .item-content-div {
    width: 400px;
    margin: 20px auto 0 auto;
    .item-div {
      .item-name-label {
        margin-right: 10px;
      }
      input {
        width: 220px;
        height: 33px;
        padding: 0 5px;
        border: 1px solid #dcdcdc;
      }
      .role-group-div {
        display: inline-flex;
        align-items: center;
        .my-select {
          width: 232px;
          height: 33px;
        }
      }
      .tips-div {
        width: 232px;
        height: 30px;
        margin-left: 80px;
      }
      .firm-name-div {
        margin-left: 80px;
        color: #787878;
      }
    }
  }
  .submit-div {
    text-align: center;
    margin-top: 15px;
    input {
      width: 120px;
      height: 35px;
      color: white;
      font-size: 16px;
      border: none;
      background: #4676c8;
      margin-top: 10px;
    }
    .tips-div {
      width: 100%;
      height: 25px;
    }
  }
}
</style>
